<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="inner_container">
    <ul class="nav nav-tabs justify-content-center"
        id="decompteFer"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="decompte_travaux_fer-tab"
           data-toggle="tab"
           href="#decompte_travaux_fer"
           role="tab"
           aria-controls="home"
           @click="apiCall('tout')"
           aria-selected="true">Tous les décomptes DGIR</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link"
           id="decompte_a_valider-tab"
           data-toggle="tab"
           href="#decompte_a_valider_dgir"
           role="tab"
           aria-controls="profile"
           @click="apiCall('en_attente')"
           aria-selected="false">Décomptes à traiter </a>
      </li>
     
      <li class="nav-item"
          v-if="user[0]=='DAF'">
        <a class="nav-link"
           id="decompte_a_decaisser-tab"
           data-toggle="tab"
           href="#decompte_a_decaisser"
           role="tab"
           aria-controls="profile"
           @click="apiCall('decaisser')"
           aria-selected="false">Décomptes à régler</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="decompte_valide-tab"
           data-toggle="tab"
           href="#decompte_valide"
           role="tab"
           aria-controls="profile"
           @click="apiCall('valide')"
           aria-selected="false">Décomptes traités</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="decompte_refuse-tab"
           data-toggle="tab"
           href="#decompte_refuse"
           role="tab"
           aria-controls="profile"
           @click="apiCall('refuse')"
           aria-selected="false">Décomptes refusés</a>
      </li>
    </ul>
  
    <!-- LES DIFFERENTS BLOCS -->
    <div class="tab-content" 
         id="myTabContent">
      <!-- Les décomptes dgir-->
      <div class="tab-pane fade show active" 
           id="decompte_travaux_dgir"
           role="tabpanel"
           aria-labelledby="home-tab">
        <div class="table_action">
          <div class="div_left">
            <button v-if="(user[0] == 'ADMIN'|| user[0] == 'DT') && user[1] == 'FER'"
                    class="btn btn-blue pl-5 pr-5 op-btn ml-2"
                    data-toggle="modal"
                    ref="modal_button"
                    data-target="#exampleModal">Importer données historiques</button>
          </div>
          <div class="div_left">
            <router-link :to="{ path: '/filtre' }"
                         class="btn btn-blue">
              Filtrer
            </router-link>
            <!-- <button class="btn btn-blue pl-5 pr-5 op-btn"
                    data-toggle="modal"
                    ref="modal_button"
                    data-target="#filtreModal">Filtrer</button> -->
          </div>
          <!-- <button class="btn btn-blue"
                  @click="downloadExport(decomptes.url)">
            Exporter
          </button> -->
        </div> 
             
        <div class="tab_holder">
          <table class="table tablestyle_3 table-striped"
                 style="width:7000px">
            <thead>
              <tr>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Type de Décompte
                </th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">
                  Statut
                </th>
                <th scope="col"
                    style="width:140px"
                    class="text-left">
                  Statut définitif
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Numéro Décompte
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Programme
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Marché
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Activité
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de prorogation de délai
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de service de prorogation de délai 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de modification d'itinéraire
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'Ordre de service de modification d'itinéraire 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la lettre de commande
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la lettre de commande 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'appel de fonds
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'appel de fonds 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'indication de la ligne lcd
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'indication de la ligne lcd 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'invitation SN
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'invitation SN 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de proposition de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de proposition de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier de confirmation de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier de confirmation de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de cotation
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de cotation 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du formulaire de sélection
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du formulaire de sélection 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du certificat de paiement
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de paiement 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de l'ordre de mission
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'ordre de mission 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Référence du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Taux d'execution physique 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la feuille de temps 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement de la facture 
                </th>

                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement du décompte 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du bon de livraison 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de participation 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du procès verbal de reception des travaux 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'attachement 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'expiration du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du certificat de service fait
                </th>
                
                <!-- <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernière décision</th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernier valideur</th> -->
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Statut du paiement</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant de la facture(FCFA)</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant du décompte(FCFA)</th>
                

              </tr>
            </thead>
            <tbody v-if="!loaded">
              <tr>
                <td colspan="21"
                    class="text-center">
                  Chargement ...
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="loaded && long != 0">
              <tr v-for="(decompte, key) in decomptes.donnees"
                  :key="key"
                  @click="launchValidation(decompte.id)"
                  class="text-uppercase">
                <td class="text-left">
                  <sup v-if="decompte.imputation_soumission!=''
                    || decompte.imputation_soumission!=null">
                    <span class="badge badge-pill badge-danger">{{decompte.imputation_soumission}}</span>
                  </sup>
                  {{ decompte.type_decompte }}
                </td>
                <td v-if="decompte.statut == 'VALIDE'"
                    class="text-left">
                  <span class="badge badge-pill badge-success">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td v-else-if="decompte.statut == 'EN_ATTENTE'"
                    class="text-left">
                  <span class="badge badge-pill badge-warning">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td v-else-if="decompte.statut == 'DEMANDE_INFORMATION'"
                    class="text-left">
                  <span class="badge badge-pill badge-danger">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="decompte.statut_final === 'VALIDE'">
                  <span class="badge badge-pill badge-success">
                    {{ decompte.statut_final }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="decompte.statut_final === 'EN ATTENTE'">
                  <span class="badge badge-pill badge-warning">
                    {{ decompte.statut_final }}
                  </span>
                </td>
                <td class="text-left">{{ decompte.numero_decompte }}</td>
                <td class="text-left">{{ decompte.programme.nom_programme }}</td>
                <td class="text-left">{{ decompte.marche.numero_marche }}</td>
                <td class="text-left">{{ decompte.activite.libelle }}</td>
                <td class="text-left">{{ decompte.reference_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ decompte.date_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ decompte.reference_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ decompte.date_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ decompte.reference_lettre_commande }}</td>
                <td class="text-left">{{ decompte.date_lettre_commande }}</td>
                <td class="text-left">{{ decompte.reference_courrier_appel_fond }}</td>
                <td class="text-left">{{ decompte.date_courrier_appel_fond }}</td>
                <td class="text-left">{{ decompte.reference_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ decompte.date_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ decompte.reference_courrier_invitation_sn }}</td>
                <td class="text-left">{{ decompte.date_courrier_invitation_sn }}</td>
                <td class="text-left">{{ decompte.reference_demande_proposition_prix }}</td>
                <td class="text-left">{{ decompte.date_demande_proposition_prix }}</td>
                <td class="text-left">{{ decompte.reference_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ decompte.date_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ decompte.reference_demande_cotation }}</td>
                <td class="text-left">{{ decompte.date_demande_cotation }}</td>
                <td class="text-left">{{ decompte.reference_formulaire_selection }}</td>
                <td class="text-left">{{ decompte.date_formulaire_selection }}</td>
                <td class="text-left">{{ decompte.reference_certificat_paiement }}</td>
                <td class="text-left">{{ decompte.date_certificat_paiement }}</td>
                <td class="text-left">{{ decompte.reference_ordre_mission }}</td>
                <td class="text-left">{{ decompte.date_ordre_mission }}</td>
                <td class="text-left">{{ decompte.reference_certificat_service_fait }}</td>
                <td class="text-left">{{ decompte.date_certificat_service_fait }}</td>
                <td class="text-left">{{ decompte.taux_execution_decompte }}</td>
                <td class="text-left">{{ decompte.date_feuille_temps }}</td>
                <td class="text-left">{{ decompte.date_decompte }}</td>
                <td class="text-left">{{ decompte.date_facture }}</td>
                <td class="text-left">{{ decompte.date_bon_livraison }}</td>
                <td class="text-left">{{ decompte.date_certificat_participation }}</td>
                <td class="text-left">{{ decompte.date_proces_verbal_reception_travaux }}</td>
                <td class="text-left">{{ decompte.date_attachement }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_ad }}</td>
                <td class="text-left">{{ decompte.expiration_caution_ad_date==null?decompte.expiration_caution_ad_date:decompte.expiration_caution_ad_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_rg }}</td>
                <td class="text-left">{{ decompte.expiration_caution_rg_date==null?decompte.expiration_caution_rg_date:decompte.expiration_caution_rg_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_definitif }}</td>
                <td class="text-left">{{ decompte.expiration_caution_definitif_date==null?decompte.expiration_caution_definitif_date:decompte.expiration_caution_definitif_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_certificat_service_fait }}</td>
                <td class="text-left">
                  {{ decompte.expiration_certificat_service_fait_date==null?decompte.expiration_certificat_service_fait_date:decompte.expiration_certificat_service_fait_text }}
                </td>
                <!-- <td class="text-left"
                    v-if="(decompte.action_dernier_valideur=='EN ATTENTE' && dernier_valideur[key]== null) 
                      || (dernier_valideur[key]== '')">{{ decompte.action_dernier_valideur }}</td>
                <td v-else-if="decompte.action_dernier_valideur=='MODIFIER'"
                    class="text-uppercase text-left">
                  Modifié</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 4 )">
                  VALIDE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 3 )">
                  APPROUVE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 2 )">
                  VERIFIE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 1 )">
                  TRAITE</td>
                <td class="text-left"
                    v-else>Aucune action</td> -->
                <td class="text-left">{{ decompte.statut_paiement }}</td>
                <!-- <td>{{ decompte.action_dernier_valideur }}</td> -->
                <!-- <td class="text-left">{{ getProfileDernierValideur(decompte.dernier_valideur) }}</td> -->
                <td class="text-right">{{ Math.round(decompte.montant_decompte).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(decompte.montant_facture_decompte).toLocaleString() }}</td>

              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="20"
                    class="text-center">Aucun decompte trouvé!</td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination -->
          <div class="row pagination">
            <div v-if="decomptes.pagination"
                 class="col-md-2 mx-auto text-center">
              <span v-if="decomptes.pagination.precedent"
                    class="badge badge-pill badge-info"
                    @click="pIndex--, paging(decomptes.pagination.precedent)"> &#60; </span>
              {{ pIndex }}
              <span v-if="decomptes.pagination.suivant"
                    class="badge badge-pill badge-info"
                    @click="pIndex++, paging(decomptes.pagination.suivant)"> &#62; </span>
            </div>
          </div>
        <!-- <div class="col-md-1 mx-auto text-center"
         v-if="pageCount !==0">
      <button @click="previewPage"
              :disabled="pagenumber ==0"
              class="badge badge-pill badge-info">
        &#60;
      </button>
      <button @click="nextPage"
              class="badge badge-pill badge-info">
        &#62;
      </button>
      <br>
      <span>page {{pagenumber+1}}/{{pageCount}}</span>
    </div> -->

        </div>
      </div>
      <!-- Pour le decompte à valider-->
      <div class="tab-pane fade" 
           id="decompte_a_valider_dgir"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <div class="div_left">
          <button class="btn btn-blue pl-5 pr-5 op-btn mt-2"
                  data-toggle="modal"
                  ref="modal_button"
                  data-target="#filtreModal">Filtrer</button>
        </div>
        <div class="tab_holder">
          <table class="table tablestyle_3 table-striped"
                 style="width:7000px">
            <thead>
              <tr>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Type de Décompte
                </th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">
                  Statut
                </th>
                <th scope="col"
                    style="width:140px"
                    class="text-left">
                  Statut définitif
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Numéro Décompte
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Programme
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Marché
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Activité
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de prorogation de délai
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de service de prorogation de délai 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de modification d'itinéraire
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'Ordre de service de modification d'itinéraire 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la lettre de commande
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la lettre de commande 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'appel de fonds
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'appel de fonds 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'indication de la ligne lcd
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'indication de la ligne lcd 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'invitation SN
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'invitation SN 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de proposition de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de proposition de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier de confirmation de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier de confirmation de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de cotation
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de cotation 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du formulaire de sélection
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du formulaire de sélection 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du certificat de paiement
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de paiement 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de l'ordre de mission
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'ordre de mission 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Référence du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Taux d'execution physique 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la feuille de temps 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement de la facture 
                </th>

                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement du décompte 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du bon de livraison 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de participation 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du procès verbal de reception des travaux 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'attachement 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'expiration du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du certificat de service fait
                </th>
                
                <!-- <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernière décision</th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernier valideur</th> -->
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Statut du paiement</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant de la facture(FCFA)</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant du décompte(FCFA)</th>
                

              </tr>
            </thead>
            <tbody v-if="!vloaded">
              <tr>
                <td colspan="21"
                    class="text-center">
                  Chargement ...
                </td>
              </tr>
            </tbody>
            <tbody v-else-if=" vlong != 0">
              <tr v-for="(vDecompte, key) in validDecomptes.donnees"
                  :key="key"
                  @click="launchValidation(vDecompte.id)"
                  class="text-uppercase">
                <td class="text-left">
                  <sup v-if="vDecompte.imputation_soumission!=''
                    || vDecompte.imputation_soumission!=null">
                    <span class="badge badge-pill badge-danger">{{vDecompte.imputation_soumission}}</span>
                  </sup>
                  {{ vDecompte.type_decompte }}
                </td>
                <td v-if="vDecompte.statut == 'VALIDE'"
                    class="text-left">
                  <span class="badge badge-pill badge-success">
                    {{ vDecompte.statut }}
                  </span>
                </td>
                <td v-else-if="vDecompte.statut == 'EN_ATTENTE'"
                    class="text-left">
                  <span class="badge badge-pill badge-warning">
                    {{ vDecompte.statut }}
                  </span>
                </td>
                <td v-else-if="vDecompte.statut == 'DEMANDE_INFORMATION'"
                    class="text-left">
                  <span class="badge badge-pill badge-danger">
                    {{ vDecompte.statut }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="vDecompte.statut_final === 'VALIDE'">
                  <span class="badge badge-pill badge-success">
                    {{ vDecompte.statut_final }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="vDecompte.statut_final === 'EN ATTENTE'">
                  <span class="badge badge-pill badge-warning">
                    {{ vDecompte.statut_final }}
                  </span>
                </td>
                <td class="text-left">{{ vDecompte.numero_decompte }}</td>
                <td class="text-left">{{ vDecompte.programme.nom_programme }}</td>
                <td class="text-left">{{ vDecompte.marche.numero_marche }}</td>
                <td class="text-left">{{ vDecompte.activite.libelle }}</td>
                <td class="text-left">{{ vDecompte.reference_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ vDecompte.date_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ vDecompte.reference_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ vDecompte.date_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ vDecompte.reference_lettre_commande }}</td>
                <td class="text-left">{{ vDecompte.date_lettre_commande }}</td>
                <td class="text-left">{{ vDecompte.reference_courrier_appel_fond }}</td>
                <td class="text-left">{{ vDecompte.date_courrier_appel_fond }}</td>
                <td class="text-left">{{ vDecompte.reference_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ vDecompte.date_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ vDecompte.reference_courrier_invitation_sn }}</td>
                <td class="text-left">{{ vDecompte.date_courrier_invitation_sn }}</td>
                <td class="text-left">{{ vDecompte.reference_demande_proposition_prix }}</td>
                <td class="text-left">{{ vDecompte.date_demande_proposition_prix }}</td>
                <td class="text-left">{{ vDecompte.reference_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ vDecompte.date_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ vDecompte.reference_demande_cotation }}</td>
                <td class="text-left">{{ vDecompte.date_demande_cotation }}</td>
                <td class="text-left">{{ vDecompte.reference_formulaire_selection }}</td>
                <td class="text-left">{{ vDecompte.date_formulaire_selection }}</td>
                <td class="text-left">{{ vDecompte.reference_certificat_paiement }}</td>
                <td class="text-left">{{ vDecompte.date_certificat_paiement }}</td>
                <td class="text-left">{{ vDecompte.reference_ordre_mission }}</td>
                <td class="text-left">{{ vDecompte.date_ordre_mission }}</td>
                <td class="text-left">{{ vDecompte.reference_certificat_service_fait }}</td>
                <td class="text-left">{{ vDecompte.date_certificat_service_fait }}</td>
                <td class="text-left">{{ vDecompte.taux_execution_decompte }}</td>
                <td class="text-left">{{ vDecompte.date_feuille_temps }}</td>
                <td class="text-left">{{ vDecompte.date_decompte }}</td>
                <td class="text-left">{{ vDecompte.date_facture }}</td>
                <td class="text-left">{{ vDecompte.date_bon_livraison }}</td>
                <td class="text-left">{{ vDecompte.date_certificat_participation }}</td>
                <td class="text-left">{{ vDecompte.date_proces_verbal_reception_travaux }}</td>
                <td class="text-left">{{ vDecompte.date_attachement }}</td>
                <td class="text-left">{{ vDecompte.date_etablissement_cautionnement_ad }}</td>
                <td class="text-left">{{ vDecompte.expiration_caution_ad_date==null?vDecompte.expiration_caution_ad_date:vDecompte.expiration_caution_ad_text }}</td>
                <td class="text-left">{{ vDecompte.date_etablissement_cautionnement_rg }}</td>
                <td class="text-left">{{ vDecompte.expiration_caution_rg_date==null?vDecompte.expiration_caution_rg_date:vDecompte.expiration_caution_rg_text }}</td>
                <td class="text-left">{{ vDecompte.date_etablissement_cautionnement_definitif }}</td>
                <td class="text-left">{{ vDecompte.expiration_caution_definitif_date==null?vDecompte.expiration_caution_definitif_date:vDecompte.expiration_caution_definitif_text }}</td>
                <td class="text-left">{{ vDecompte.date_etablissement_certificat_service_fait }}</td>
                <td class="text-left">
                  {{ vDecompte.expiration_certificat_service_fait_date==null?vDecompte.expiration_certificat_service_fait_date:vDecompte.expiration_certificat_service_fait_text }}
                </td>
                <!-- <td class="text-left"
                    v-if="(vDecompte.action_dernier_valideur=='EN ATTENTE' && dernier_valideur[key]== null) 
                      || (dernier_valideur[key]== '')">{{ vDecompte.action_dernier_valideur }}</td>
                <td v-else-if="vDecompte.action_dernier_valideur=='MODIFIER'"
                    class="text-uppercase text-left">
                  Modifié</td>
                <td class="text-left"
                    v-else-if="(vDecompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 4 )">
                  VALIDE</td>
                <td class="text-left"
                    v-else-if="(vDecompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 3 )">
                  APPROUVE</td>
                <td class="text-left"
                    v-else-if="(vDecompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 2 )">
                  VERIFIE</td>
                <td class="text-left"
                    v-else-if="(vDecompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 1 )">
                  TRAITE</td>
                <td class="text-left"
                    v-else>Aucune action</td> -->
                <td class="text-left">{{ vDecompte.statut_paiement }}</td>
                <!-- <td>{{ vDecompte.action_dernier_valideur }}</td> -->
                <!-- <td class="text-left">{{ getProfileDernierValideur(vDecompte.dernier_valideur) }}</td> -->
                <td class="text-right">{{ Math.round(vDecompte.montant_decompte).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(vDecompte.montant_facture_decompte).toLocaleString() }}</td>

              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="20"
                    class="text-center">Aucun decompte trouvé!</td>
              </tr>
            </tbody>
          </table>
          <!-- pagination -->
          <div class="row pagination">
            <div v-if="validDecomptes.pagination"
                 class="col-md-2 mx-auto text-center">
              <span v-if="validDecomptes.pagination.precedent"
                    class="badge badge-pill badge-info"
                    @click="vIndex--, paging(validDecomptes.pagination.precedent)"> &#60; </span>
              {{ pIndex }}
              <span v-if="validDecomptes.pagination.suivant"
                    class="badge badge-pill badge-info"
                    @click="vIndex++, paging(validDecomptes.pagination.suivant)"> &#62; </span>
            </div>
          </div>
        <!-- <div class="col-md-1 mx-auto text-center"
                v-if="pageCountValidation !==0">
                <button @click="previewPageValidation"
                    :disabled="pageNumberValidation ==0"
                    class="badge badge-pill badge-info">
                &#60;
                </button>
                <button @click="nextPageValidation"
                    class="badge badge-pill badge-info">
                &#62;
                </button>
                <br>
                <span>page {{pageNumberValidation+1}}/{{pageCountValidation}}</span>
                </div> -->
        </div>
      </div>

      <!-- Les décomptes validés-->
      <div class="tab-pane fade" 
           id="decompte_valide"
           role="tabpanel"
           aria-labelledby="home-tab">
             
        <div class="tab_holder">
          <table class="table tablestyle_3 table-striped"
                 style="width:7000px">
            <thead>
              <tr>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Type de Décompte
                </th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">
                  Statut
                </th>
                <th scope="col"
                    style="width:140px"
                    class="text-left">
                  Statut définitif
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Numéro Décompte
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Programme
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Marché
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Activité
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de prorogation de délai
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de service de prorogation de délai 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de modification d'itinéraire
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'Ordre de service de modification d'itinéraire 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la lettre de commande
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la lettre de commande 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'appel de fonds
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'appel de fonds 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'indication de la ligne lcd
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'indication de la ligne lcd 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'invitation SN
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'invitation SN 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de proposition de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de proposition de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier de confirmation de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier de confirmation de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de cotation
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de cotation 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du formulaire de sélection
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du formulaire de sélection 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du certificat de paiement
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de paiement 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de l'ordre de mission
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'ordre de mission 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Référence du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Taux d'execution physique 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la feuille de temps 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement de la facture 
                </th>

                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement du décompte 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du bon de livraison 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de participation 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du procès verbal de reception des travaux 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'attachement 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'expiration du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du certificat de service fait
                </th>
                
                <!-- <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernière décision</th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernier valideur</th> -->
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Statut du paiement</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant de la facture(FCFA)</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant du décompte(FCFA)</th>
                

              </tr>
            </thead>
            <tbody v-if="!loaded">
              <tr>
                <td colspan="21"
                    class="text-center">
                  Chargement ...
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="loaded && decomptes_valides.length != 0">
              <tr v-for="(decompte, key) in decomptes_valides"
                  :key="key"
                  @click="launchValidation(decompte.id)"
                  class="text-uppercase">
                <td class="text-left">
                  <sup v-if="decompte.imputation_soumission!=''
                    || decompte.imputation_soumission!=null">
                    <span class="badge badge-pill badge-danger">{{decompte.imputation_soumission}}</span>
                  </sup>
                  {{ decompte.type_decompte }}
                </td>
                <td v-if="decompte.statut == 'VALIDE'"
                    class="text-left">
                  <span class="badge badge-pill badge-success">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td v-else-if="decompte.statut == 'EN_ATTENTE'"
                    class="text-left">
                  <span class="badge badge-pill badge-warning">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td v-else-if="decompte.statut == 'DEMANDE_INFORMATION'"
                    class="text-left">
                  <span class="badge badge-pill badge-danger">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="decompte.statut_final === 'VALIDE'">
                  <span class="badge badge-pill badge-success">
                    {{ decompte.statut_final }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="decompte.statut_final === 'EN ATTENTE'">
                  <span class="badge badge-pill badge-warning">
                    {{ decompte.statut_final }}
                  </span>
                </td>
                <td class="text-left">{{ decompte.numero_decompte }}</td>
                <td class="text-left">{{ decompte.programme.nom_programme }}</td>
                <td class="text-left">{{ decompte.marche.numero_marche }}</td>
                <td class="text-left">{{ decompte.activite.libelle }}</td>
                <td class="text-left">{{ decompte.reference_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ decompte.date_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ decompte.reference_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ decompte.date_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ decompte.reference_lettre_commande }}</td>
                <td class="text-left">{{ decompte.date_lettre_commande }}</td>
                <td class="text-left">{{ decompte.reference_courrier_appel_fond }}</td>
                <td class="text-left">{{ decompte.date_courrier_appel_fond }}</td>
                <td class="text-left">{{ decompte.reference_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ decompte.date_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ decompte.reference_courrier_invitation_sn }}</td>
                <td class="text-left">{{ decompte.date_courrier_invitation_sn }}</td>
                <td class="text-left">{{ decompte.reference_demande_proposition_prix }}</td>
                <td class="text-left">{{ decompte.date_demande_proposition_prix }}</td>
                <td class="text-left">{{ decompte.reference_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ decompte.date_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ decompte.reference_demande_cotation }}</td>
                <td class="text-left">{{ decompte.date_demande_cotation }}</td>
                <td class="text-left">{{ decompte.reference_formulaire_selection }}</td>
                <td class="text-left">{{ decompte.date_formulaire_selection }}</td>
                <td class="text-left">{{ decompte.reference_certificat_paiement }}</td>
                <td class="text-left">{{ decompte.date_certificat_paiement }}</td>
                <td class="text-left">{{ decompte.reference_ordre_mission }}</td>
                <td class="text-left">{{ decompte.date_ordre_mission }}</td>
                <td class="text-left">{{ decompte.reference_certificat_service_fait }}</td>
                <td class="text-left">{{ decompte.date_certificat_service_fait }}</td>
                <td class="text-left">{{ decompte.taux_execution_decompte }}</td>
                <td class="text-left">{{ decompte.date_feuille_temps }}</td>
                <td class="text-left">{{ decompte.date_decompte }}</td>
                <td class="text-left">{{ decompte.date_facture }}</td>
                <td class="text-left">{{ decompte.date_bon_livraison }}</td>
                <td class="text-left">{{ decompte.date_certificat_participation }}</td>
                <td class="text-left">{{ decompte.date_proces_verbal_reception_travaux }}</td>
                <td class="text-left">{{ decompte.date_attachement }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_ad }}</td>
                <td class="text-left">{{ decompte.expiration_caution_ad_date==null?decompte.expiration_caution_ad_date:decompte.expiration_caution_ad_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_rg }}</td>
                <td class="text-left">{{ decompte.expiration_caution_rg_date==null?decompte.expiration_caution_rg_date:decompte.expiration_caution_rg_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_definitif }}</td>
                <td class="text-left">{{ decompte.expiration_caution_definitif_date==null?decompte.expiration_caution_definitif_date:decompte.expiration_caution_definitif_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_certificat_service_fait }}</td>
                <td class="text-left">
                  {{ decompte.expiration_certificat_service_fait_date==null?decompte.expiration_certificat_service_fait_date:decompte.expiration_certificat_service_fait_text }}
                </td>
                <!-- <td class="text-left"
                    v-if="(decompte.action_dernier_valideur=='EN ATTENTE' && dernier_valideur[key]== null) 
                      || (dernier_valideur[key]== '')">{{ decompte.action_dernier_valideur }}</td>
                <td v-else-if="decompte.action_dernier_valideur=='MODIFIER'"
                    class="text-uppercase text-left">
                  Modifié</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 4 )">
                  VALIDE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 3 )">
                  APPROUVE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 2 )">
                  VERIFIE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 1 )">
                  TRAITE</td>
                <td class="text-left"
                    v-else>Aucune action</td> -->
                <td class="text-left">{{ decompte.statut_paiement }}</td>
                <!-- <td>{{ decompte.action_dernier_valideur }}</td> -->
                <!-- <td class="text-left">{{ getProfileDernierValideur(decompte.dernier_valideur) }}</td> -->
                <td class="text-right">{{ Math.round(decompte.montant_decompte).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(decompte.montant_facture_decompte).toLocaleString() }}</td>

              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="20"
                    class="text-center">Aucun decompte trouvé!</td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination -->
          <div class="row pagination">
            <div v-if="decomptes.pagination"
                 class="col-md-2 mx-auto text-center">
              <span v-if="decomptes.pagination.precedent"
                    class="badge badge-pill badge-info"
                    @click="pIndex--, paging(decomptes.pagination.precedent)"> &#60; </span>
              {{ pIndex }}
              <span v-if="decomptes.pagination.suivant"
                    class="badge badge-pill badge-info"
                    @click="pIndex++, paging(decomptes.pagination.suivant)"> &#62; </span>
            </div>
          </div>

        </div>
      </div>

      <!-- Pour la décompte à decaisser-->
      <div class="tab-pane fade" 
           id="decompte_a_decaisser_dgir"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <div class="table_action">
          <div class="row">
            <div class="div_left">
              <router-link :to="{ path: '/decompte/fer/travaux' }"
                           class="btn_round">
                Ajouter un décompte
              </router-link>
            </div>
          </div>
          <div class="div_left">
            <button v-if="(user[0] == 'ADMIN'|| user[0] == 'DT') && user[1] == 'FER'"
                    class="btn btn-blue pl-5 pr-5 op-btn ml-2"
                    data-toggle="modal"
                    ref="modal_button"
                    data-target="#exampleModal">Importer données historiques</button>
          </div>
          <div class="div_left">
            <button class="btn btn-blue pl-5 pr-5 op-btn"
                    data-toggle="modal"
                    ref="modal_button"
                    data-target="#filtreModal">Filtrer</button>
          </div>
          <!-- <button class="btn btn-blue"
                  @click="downloadExport(decomptes.url)">
            Exporter
          </button> -->
        </div>

        <div class="tab_holder">
          <table class="table tablestyle_3 table-striped">
            <thead>
              <tr>
                <th scope="col"
                    class="text-left th-blue">
                  Département
                </th>
                <th scope="col"
                    class="th-blue">
                  Marché
                </th>
                <th scope="col"
                    class="text-left th-blue">
                  Numéro Décompte
                </th>
                <th scope="col"
                    class="th-blue">
                  Maitrise d'ouvrage déléguée
                </th>
                <th scope="col"
                    class="th-blue">
                  Maitrise d'ouvrage
                </th>
                <th scope="col"
                    class="text-left th-blue">
                  Entreprise
                </th>
                <th scope="col">Montant (FCFA)</th>
                <th scope="col">Statut du paiement</th>

              </tr>
            </thead>
            <tbody v-if="!loaded">
              <tr>
                <td colspan="21"
                    class="text-center">
                  Chargement ...
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="long != 0">
              <tr v-for="(decdecompte, deckey) in decaissDecomptes.donnees"
                  :key="deckey"
                  @click="launchValidation(decdecompte.id)">
                <td>{{ decdecompte.departement }}</td> 
                <td>{{ decdecompte.numero_marche_ou_lettre_commande }}</td>
                <td>{{ decdecompte.numero_decompte }}</td>
                <td>{{ decdecompte.maitre_oeuvre }}</td>
                <td>{{ decdecompte.maitre_oeuvre_deleguee }}</td>
                <td>{{ decdecompte.entreprise }}</td>
                <td class="text-right">{{ Math.round(decdecompte.montant_facture_decompte).toLocaleString() }}</td>
                <td>{{ decdecompte.statut_paiement }}</td>

              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="20"
                    class="text-center">Aucun decompte trouvé!</td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination -->
          <div class="row pagination">
            <div v-if="decomptes.pagination"
                 class="col-md-2 mx-auto text-center">
              <span v-if="decomptes.pagination.precedent"
                    class="badge badge-pill badge-info"
                    @click="pIndex--, paging(decomptes.pagination.precedent)"> &#60; </span>
              {{ pIndex }}
              <span v-if="decomptes.pagination.suivant"
                    class="badge badge-pill badge-info"
                    @click="pIndex++, paging(decomptes.pagination.suivant)"> &#62; </span>
            </div>
          </div>
        <!-- <div class="col-md-1 mx-auto text-center"
              v-if="pageCount !==0">
          <button @click="previewPage"
                  :disabled="pagenumber ==0"
                  class="badge badge-pill badge-info">
            &#60;
          </button>
          <button @click="nextPage"
                  class="badge badge-pill badge-info">
            &#62;
          </button>
          <br>
          <span>page {{pagenumber+1}}/{{pageCount}}</span>
        </div> -->

        </div>
      </div>
      
      <!-- Les décomptes rejetés-->
      <div class="tab-pane fade" 
           id="decompte_refuse"
           role="tabpanel"
           aria-labelledby="home-tab">
             
        <div class="tab_holder">
          <table class="table tablestyle_3 table-striped"
                 style="width:7000px">
            <thead>
              <tr>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Type de Décompte
                </th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">
                  Statut
                </th>
                <th scope="col"
                    style="width:140px"
                    class="text-left">
                  Statut définitif
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Numéro Décompte
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Programme
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Marché
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Activité
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de prorogation de délai
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de service de prorogation de délai 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence Ordre de service de modification d'itinéraire
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'Ordre de service de modification d'itinéraire 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la lettre de commande
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la lettre de commande 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'appel de fonds
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'appel de fonds 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'indication de la ligne lcd
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'indication de la ligne lcd 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier d'invitation SN
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier d'invitation SN 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de proposition de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de proposition de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du courrier de confirmation de prix
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du courrier de confirmation de prix 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de la demande de cotation
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la demande de cotation 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du formulaire de sélection
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du formulaire de sélection 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence du certificat de paiement
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de paiement 
                </th>
                <th scope="col"
                    class="text-left th-blue"
                    style="width:140px;">
                  Référence de l'ordre de mission
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'ordre de mission 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Référence du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Taux d'execution physique 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de la feuille de temps 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement de la facture 
                </th>

                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date d'établissement du décompte 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du bon de livraison 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du certificat de participation 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date du procès verbal de reception des travaux 
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:140px;">
                  Date de l'attachement 
                </th>
                
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'expiration du cautionnement d'avance de demarrage
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:180px;">
                  Date d'établissement du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement de la retenue garantie
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du cautionnement définitif
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'établissement du certificat de service fait
                </th>
                <th scope="col"
                    class="th-blue text-left"
                    style="width:160px;">
                  Date d'expiration du certificat de service fait
                </th>
                
                <!-- <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernière décision</th>
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Dernier valideur</th> -->
                <th scope="col"
                    class="text-left"
                    style="width:140px;">Statut du paiement</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant de la facture(FCFA)</th>
                <th scope="col"
                    class="text-right"
                    style="width:140px;">Montant du décompte(FCFA)</th>
                

              </tr>
            </thead>
            <tbody v-if="!loaded">
              <tr>
                <td colspan="21"
                    class="text-center">
                  Chargement ...
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="loaded && decomptes_rejetes.length != 0">
              <tr v-for="(decompte, key) in decomptes_rejetes"
                  :key="key"
                  @click="launchValidation(decompte.id)"
                  class="text-uppercase">
                <td class="text-left">
                  <sup v-if="decompte.imputation_soumission!=''
                    || decompte.imputation_soumission!=null">
                    <span class="badge badge-pill badge-danger">{{decompte.imputation_soumission}}</span>
                  </sup>
                  {{ decompte.type_decompte }}
                </td>
                <td v-if="decompte.statut == 'VALIDE'"
                    class="text-left">
                  <span class="badge badge-pill badge-success">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td v-else-if="decompte.statut == 'EN_ATTENTE'"
                    class="text-left">
                  <span class="badge badge-pill badge-warning">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td v-else-if="decompte.statut == 'DEMANDE_INFORMATION'"
                    class="text-left">
                  <span class="badge badge-pill badge-danger">
                    {{ decompte.statut }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="decompte.statut_final === 'VALIDE'">
                  <span class="badge badge-pill badge-success">
                    {{ decompte.statut_final }}
                  </span>
                </td>
                <td class="text-left"
                    v-if="decompte.statut_final === 'EN ATTENTE'">
                  <span class="badge badge-pill badge-warning">
                    {{ decompte.statut_final }}
                  </span>
                </td>
                <td class="text-left">{{ decompte.numero_decompte }}</td>
                <td class="text-left">{{ decompte.programme.nom_programme }}</td>
                <td class="text-left">{{ decompte.marche.numero_marche }}</td>
                <td class="text-left">{{ decompte.activite.libelle }}</td>
                <td class="text-left">{{ decompte.reference_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ decompte.date_ordre_service_prorogation_delai }}</td>
                <td class="text-left">{{ decompte.reference_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ decompte.date_ordre_service_modification_itineraire }}</td>
                <td class="text-left">{{ decompte.reference_lettre_commande }}</td>
                <td class="text-left">{{ decompte.date_lettre_commande }}</td>
                <td class="text-left">{{ decompte.reference_courrier_appel_fond }}</td>
                <td class="text-left">{{ decompte.date_courrier_appel_fond }}</td>
                <td class="text-left">{{ decompte.reference_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ decompte.date_courrier_indication_ligne_lcd }}</td>
                <td class="text-left">{{ decompte.reference_courrier_invitation_sn }}</td>
                <td class="text-left">{{ decompte.date_courrier_invitation_sn }}</td>
                <td class="text-left">{{ decompte.reference_demande_proposition_prix }}</td>
                <td class="text-left">{{ decompte.date_demande_proposition_prix }}</td>
                <td class="text-left">{{ decompte.reference_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ decompte.date_courrier_confirmation_prix }}</td>
                <td class="text-left">{{ decompte.reference_demande_cotation }}</td>
                <td class="text-left">{{ decompte.date_demande_cotation }}</td>
                <td class="text-left">{{ decompte.reference_formulaire_selection }}</td>
                <td class="text-left">{{ decompte.date_formulaire_selection }}</td>
                <td class="text-left">{{ decompte.reference_certificat_paiement }}</td>
                <td class="text-left">{{ decompte.date_certificat_paiement }}</td>
                <td class="text-left">{{ decompte.reference_ordre_mission }}</td>
                <td class="text-left">{{ decompte.date_ordre_mission }}</td>
                <td class="text-left">{{ decompte.reference_certificat_service_fait }}</td>
                <td class="text-left">{{ decompte.date_certificat_service_fait }}</td>
                <td class="text-left">{{ decompte.taux_execution_decompte }}</td>
                <td class="text-left">{{ decompte.date_feuille_temps }}</td>
                <td class="text-left">{{ decompte.date_decompte }}</td>
                <td class="text-left">{{ decompte.date_facture }}</td>
                <td class="text-left">{{ decompte.date_bon_livraison }}</td>
                <td class="text-left">{{ decompte.date_certificat_participation }}</td>
                <td class="text-left">{{ decompte.date_proces_verbal_reception_travaux }}</td>
                <td class="text-left">{{ decompte.date_attachement }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_ad }}</td>
                <td class="text-left">{{ decompte.expiration_caution_ad_date==null?decompte.expiration_caution_ad_date:decompte.expiration_caution_ad_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_rg }}</td>
                <td class="text-left">{{ decompte.expiration_caution_rg_date==null?decompte.expiration_caution_rg_date:decompte.expiration_caution_rg_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_cautionnement_definitif }}</td>
                <td class="text-left">{{ decompte.expiration_caution_definitif_date==null?decompte.expiration_caution_definitif_date:decompte.expiration_caution_definitif_text }}</td>
                <td class="text-left">{{ decompte.date_etablissement_certificat_service_fait }}</td>
                <td class="text-left">
                  {{ decompte.expiration_certificat_service_fait_date==null?decompte.expiration_certificat_service_fait_date:decompte.expiration_certificat_service_fait_text }}
                </td>
                <!-- <td class="text-left"
                    v-if="(decompte.action_dernier_valideur=='EN ATTENTE' && dernier_valideur[key]== null) 
                      || (dernier_valideur[key]== '')">{{ decompte.action_dernier_valideur }}</td>
                <td v-else-if="decompte.action_dernier_valideur=='MODIFIER'"
                    class="text-uppercase text-left">
                  Modifié</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 4 )">
                  VALIDE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 3 )">
                  APPROUVE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 2 )">
                  VERIFIE</td>
                <td class="text-left"
                    v-else-if="(decompte.action_dernier_valideur=='VALIDE' && dernier_valideur[key][4]== 1 )">
                  TRAITE</td>
                <td class="text-left"
                    v-else>Aucune action</td> -->
                <td class="text-left">{{ decompte.statut_paiement }}</td>
                <!-- <td>{{ decompte.action_dernier_valideur }}</td> -->
                <!-- <td class="text-left">{{ getProfileDernierValideur(decompte.dernier_valideur) }}</td> -->
                <td class="text-right">{{ Math.round(decompte.montant_decompte).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(decompte.montant_facture_decompte).toLocaleString() }}</td>

              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="20"
                    class="text-center">Aucun decompte trouvé!</td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination -->
          <div class="row pagination">
            <div v-if="decomptes.pagination"
                 class="col-md-2 mx-auto text-center">
              <span v-if="decomptes.pagination.precedent"
                    class="badge badge-pill badge-info"
                    @click="pIndex--, paging(decomptes.pagination.precedent)"> &#60; </span>
              {{ pIndex }}
              <span v-if="decomptes.pagination.suivant"
                    class="badge badge-pill badge-info"
                    @click="pIndex++, paging(decomptes.pagination.suivant)"> &#62; </span>
            </div>
          </div>

        </div>
      </div>
  
    </div>
  </div>
</template>
  
  <script>
import { mapActions,mapMutations, mapGetters } from "vuex"
import utils from "@/assets/js/utils"
import form from "vuejs-form"
export default {
  name : "DecompteDgir",
  components:{

  },
  data: () => ({
    decompteData: {
      libelle: null,
      order: null,
      tri: null
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    validateData: {
      libelle: null,
      order: "asc",
      tri: null,
      en_attente: 1,
      filtre:"",
      groupe:"dgir"
    },
    inputFiles: {  
      donnees_histo:"" 
    },
    filtre:[],
    decomptes_valides:[],
    decomptes_rejetes:[],
    chmp:[
      {
        valeur:"numero_decompte",
        type:"caractere",
        table:"decomptes",
        libelle:"Numéro décompte",
      },
      {
        valeur:"nom_programme",
        type:"caractere",
        table:"programmes",
        libelle:"Programme",
      },
      {
        valeur:"numero_marche",
        type:"caractere",
        table:"marches",
        libelle:"Marché",
      },
      {
        valeur:"libelle",
        type:"caractere",
        table:"activites",
        libelle:"Activité",
      },
      {
        valeur:"objet",
        type:"caractere",
        table:"decomptes",
        libelle:"Objet des travaux",
      },
      {
        valeur:"type_entretien",
        type:"caractere",
        table:"decomptes",
        libelle:"Type d'entretien",
      },
      {
        valeur:"date_facture",
        type:"date",
        table:"decomptes",
        libelle:"Date de transmission",
      },
      {
        valeur:"date_approbation",
        type:"date",
        table:"decomptes",
        libelle:"Date d'approbation",
      },
      {
        valeur:"date_debut_execution",
        type:"date",
        table:"decomptes",
        libelle:"Date de début d'exécution",
      },
      {
        valeur:"date_demarrage_effectif",
        type:"date",
        table:"decomptes",
        libelle:"Date de démarrage effectif",
      },
      {
        valeur:"date_fin_execution",
        type:"date",
        table:"decomptes",
        libelle:"Date de fin d'exécution",
      },
      {
        valeur:"date_ordre_service",
        type:"date",
        table:"decomptes",
        libelle:"Date d'ordre de service",
      },
      {
        valeur:"date_ordonnancement",
        type:"date",
        table:"decomptes",
        libelle:"Date d'ordonnancement",
      },
      {
        valeur:"montant_ordonnancement",
        type:"numeric",
        table:"decomptes",
        libelle:"Montant d'ordonnancement",
      },
      {
        valeur:"montant_facture_decompte",
        type:"numeric",
        table:"decomptes",
        libelle:"Montant",
      },
    ],
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    baseUrl: process.env.VUE_APP_UPLOAD,
    url:"",
    pIndex: 1,
    statDecompte:"en_attente",
    long: "",
    vlong: "",
    dlong: "",
    vIndex: 1,
    dIndex: 1,
    loaded: false,
    loading:false,
    vloaded: false,
    dloaded: false,
    pagenumber:0,
    size:15,
    type:"general",
    groupe:"dgir",
    user: "",
    profil:"",
    listExtensionFiles:"",
    pageNumberValidation:0,
    dernier_valideur:[],
    vdernier_valideur:[],
  }),
  watch: {
    decomptes() {
      if (this.decomptes.donnees) {
        this.loaded = true
        this.long = this.decomptes.donnees.length
        // this.$refs["btn-close"].click()
        // var count_rejete=0
        // this.decomptes_rejetes=[]
        // this.decomptes_valides=[]
        this.decomptes.donnees.forEach(decompte => {
          // get le dernier valideur pour la condition du champ "dernière décision"
          // if (decompte.dernier_valideur != null) {
          //   this.dernier_valideur.push(decompte.dernier_valideur.split("_"))
          // }else{
          //   this.dernier_valideur.push(decompte.dernier_valideur)
          // }
          if (decompte.statut_final !== "VALIDE") {
            // console.log(decompte.statut_final)
            decompte.statut_final = decompte.statut_final.split("_")
            decompte.statut_final = decompte.statut_final[0] + " " + decompte.statut_final[1]
            
            // if (decompte.statut == "DEMANDE_INFORMATION") {
            //   var chaine = decompte.statut.split("_")[0] + " " + decompte.statut.split("_")[1]
            //   this.decomptes_rejetes.push(decompte)
            //   this.decomptes_rejetes[count_rejete].statut=chaine
            //   count_rejete++
            // }
          }//else{
          //   this.decomptes_valides.push(decompte)
          // }
        })
        // console.log(this.dernier_valideur)
      }
    },
    validDecomptes() {
      this.vloaded = true
      this.vlong = this.validDecomptes.donnees.length
      // console.log(this.validDecomptes)
      this.validDecomptes.donnees.forEach(decompte => {
        if (decompte.statut_final != "VALIDE") {
          decompte.statut_final = decompte.statut_final.split("_")
          decompte.statut_final = decompte.statut_final[0] + " " + decompte.statut_final[1]
          // if (decompte.action_dernier_valideur !== "VALIDE" && decompte.action_dernier_valideur !== "MODIFIER" && decompte.action_dernier_valideur !== null) {
          //   decompte.action_dernier_valideur = decompte.action_dernier_valideur.split("_")
          //   decompte.action_dernier_valideur = decompte.action_dernier_valideur[0] + " " + decompte.action_dernier_valideur[1]
          // }
        }
      })
      switch (this.statDecompte) {
        case "valide":
          this.decomptes_valides = this.validDecomptes.donnees
          break
        case "refuse":
          this.decomptes_refuses = this.validDecomptes.donnees
          break
      }
    },
    decaissDecomptes() {
      this.dloaded = true
      this.dlong = this.decaissDecomptes.donnees.length
      console.log(this.decaissDecomptes)
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
        this.loading=false
      }
    },
    decompterrors(){
      if (this.decompterrors) {
        this.notif.message = this.decompterrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
            this.setError("")
            this.setFiles("")
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
    }
  },
  mounted() {
    localStorage.setItem("module","decomptes")
    this.Decomptes({groupe:this.groupe,filtre:this.filtre})
    // this.DecomptesValider(this.validateData)
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.listExtensionFiles=utils.formaFichierAccepte
    this.user = userdetails.user_role.split("_")
    if(this.user[0] != "ADMIN"){
      this.profil = userdetails.user_profil.split("_")
    }
    if (this.user[0]=="DAF" || this.user[0]=="ADMIN") {
      this.DecomptesDecaisser()
    }
      
  },
  computed: {
    ...mapGetters(["decomptes", "files", "errors", "validDecomptes","decompterrors","decaissDecomptes","failDecaissDecompte"]),
    // pageCount(){
    //   if(this.decomptes.donnees !== undefined){
    //     var l = this.decomptes.donnees.length
    //     var s = this.size
    //   }
    //   return Math.ceil(l/s)
    // },
    // listDonneeDecompte(){
    //   const start = this.pagenumber * this.size,
    //         end = start + this.size
    //   return this.decomptes.donnees.slice(start,end)
    // },
  
    // pageCountValidation(){
    //   return Math.ceil(this.vlong/this.size)
    // },
    // lesDecompteAValider(){
    //   const debut= this.pageNumberValidation * this.size,
    //         fin =debut + this.size
    //   return this.validDecomptes.donnees.slice(debut,fin)
    // }
  },
  methods: {
    ...mapActions(["Decomptes", "DecomptesValider", "pageDecomptes","saveFile", "importDecompte","DecomptesDecaisser"]),
    ...mapMutations(["setDecomptes", "setValidDecomptes","setError","setFiles","setDecaissDecomptes","setFailDecaissDecomptes"]),
    launchValidation(id) {
      this.$router.push({ name: "DecDetail", params: { id: id } })
    },
    filtrer(type) {
      if (type == "general") {
        this.loaded = false
        this.long = null
        this.Decomptes(this.filtre)
      } else {
        this.vloaded = false
        this.vlong = null
        this.validateData.filtre=this.filtre
        this.DecomptesValider(this.validateData)
      }
    },
    restoring(type) {
      if (type == "general") {
        this.loaded = false
        this.long = null
        // this.setMarches("")
        this.Decomptes({groupe:this.groupe,filtre:this.filtre})
        this.decompteData = { libelle: null, order: null, tri: null }
      } else {
        this.vloaded = false
        this.vlong = null
        this.validateData = { libelle: null, order: "asc", tri: null, en_attente: 1 }
        this.DecomptesValider(this.validateData)
      }
    },
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      // this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]
      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
        this.loading= true
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    submit() {
      this.importDecompte({url:this.url})
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      if (this.vIndex == 0) {
        this.vIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      var count=1
      var used=0
      url+="&groupe="+this.groupe+"&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.loaded = false
      this.long = null
      this.vloaded = false
      this.vlong = null
      this.setDecomptes("")
      this.setValidDecomptes("")
      this.pageDecomptes(url)
    },
    getProfileDernierValideur(valideur) {
      if (valideur !== null) {
        return utils.getProfileDernierValideur(valideur)
      } else {
        return "non validé"
      }
    },
    getTypeDecompte(type) {
      return (utils.getTypeDecompte(type)[1] + " " + utils.getTypeDecompte(type)[2]).toUpperCase()
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    viderFile(){
      this.inputFiles.donnees_histo=''
      this.setFiles('')
      this.$refs["donnees_histo"].value=""
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    apiCall(statut){
      this.statDecompte=statut
      switch (this.statDecompte) {
        case "valide":
          this.validateData = { libelle: null, order: "asc", tri: null, en_attente: null, valide: 1, refuse: null,}
          this.DecomptesValider(this.validateData)
          break
        case "refuse":
          this.validateData = { libelle: null, order: "asc", tri: null, en_attente: null, valide: null, refuse: 1,}
          this.DecomptesValider(this.validateData)
          break
        case "en_attente":
          this.validateData = { libelle: null, order: "asc", tri: null, en_attente: 1, valide: null, refuse: null,}
          this.DecomptesValider(this.validateData)
          break
        case "decaisser":
          this.DecomptesDecaisser()
          break
        default:
          this.Decomptes({groupe:this.groupe,filtre:this.filtre})
          break
      }
    }
  }
}
  </script>
  
  