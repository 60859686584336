
<template>
  <div class="main_container">
    <div class="col-12 mb-4 bread-crumbs">
      <span> Décomptes</span>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des décomptes
          </div>
        </div>
      </div>
    </div>
  
    <!-- BLOC DES CLASSEMENTS DES DECOMPTES -->
    <ul class="nav nav-tabs"
        id="myTab"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="vue-tab"
           data-toggle="tab"
           href="#vue"
           role="tab"
           aria-controls="home"
           aria-selected="true"
           @click="selectionOnglet('fer')">
          <sup v-if="nb_nouveau_decomptes!=''
            && nb_nouveau_decomptes!=null && (nb_nouveau_decomptes[0].notification_FER > 0)">
            <span class="badge badge-pill badge-danger">{{nb_nouveau_decomptes[0].notification_FER}}</span>
          </sup>
          FER
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="dgir-tab"
           data-toggle="tab"
           href="#dgir"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selectionOnglet('dgir')">
          <sup v-if="nb_nouveau_decomptes!=''
            && nb_nouveau_decomptes!=null && (nb_nouveau_decomptes[0].notification_DGIR > 0)">
            <span class="badge badge-pill badge-danger">{{nb_nouveau_decomptes[0].notification_DGIR}}</span>
          </sup>
          DGIR
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="ageroute-tab"
           data-toggle="tab"
           href="#ageroute"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selectionOnglet('ageroute')">
          <sup v-if="nb_nouveau_decomptes!=''
            && nb_nouveau_decomptes!=null && (nb_nouveau_decomptes[0].notification_AGEROUTE > 0)">
            <span class="badge badge-pill badge-danger">{{nb_nouveau_decomptes[0].notification_AGEROUTE}}</span>
          </sup>
          AGEROUTE
        </a>
      </li>
    </ul>
    <div class="row">
      <Notif :notif="notif" />
    </div>

    <!-- BLOC CLASSEMENT DES DECOMPTES FER -->
    <div class="tab-content"
         id="myTabContent">
      <!-- vue -->
      <div class="tab-pane fade show active"
           id="vue"
           role="tabpanel"
           aria-labelledby="home-tab">
        
        <DecompteFer v-if="selection=='fer'"/>
      </div>

      <div class="tab-pane fade "
           id="dgir"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <DecompteDgir v-if="selection=='dgir'"/>
      </div>

      <div class="tab-pane fade "
           id="ageroute"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <DecompteAgeroute v-if="selection=='ageroute'"/>
      </div>

    </div>

   
    <!-- Modal decompte-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données historiques
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-11">
                  <label for="">Décompte </label>
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.donnees_histo }}
                    </label>

                    <input type="file"
                           name="etat_comptable"
                           class="custom-file-input"
                           id="donnees_histo"
                           ref="donnees_histo"
                           v-on:change="handleFileUpload('donnees_histo')"
                           accept=".xlsx,.csv" />
                    <span v-if="form.errors().has('donnees_histo')"
                          v-text="form.errors().get('donnees_histo')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading"/>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('decomptesFinal.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal"
                    @click="viderFile()">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="submit">Importer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer(type)">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./decompte.css";
/* table.table.tablestyle_3.table-striped tbody td,
table.table.tablestyle_3.table-striped thead th{
  font-size: 0.6em;
}
ul li.nav-item a.nav-link{
  font-size:0.8em;
} */
table.table.tablestyle_3.table-striped{
  width: 155%;
}
</style>
<script>
import { mapActions,mapMutations, mapGetters } from "vuex"
import utils from "@/assets/js/utils"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import Loader from "@/components/shared/Spinner"
import ListFilter from "@/components/shared/Filter"
import DecompteFer from './DecompteDetail/DecompteFer.vue'
import DecompteDgir from './DecompteDetail/DecompteDgir.vue'
import DecompteAgeroute from './DecompteDetail/DecompteAgeroute.vue'
export default {
  name: "Decompte",
  components:{
    Loader,
    ListFilter,
    Notif,
    DecompteFer,
    DecompteDgir,
    DecompteAgeroute
  },
  data: () => ({
    decompteData: {
      libelle: null,
      order: null,
      tri: null
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    validateData: {
      libelle: null,
      order: "asc",
      tri: null,
      en_attente: 1,
      filtre:""
    },
    inputFiles: {  
      donnees_histo:"" 
    },
    filtre:[],
    chmp:[
      {
        valeur:"numero_decompte",
        type:"caractere",
        table:"decomptes",
        libelle:"Numéro décompte",
      },
      {
        valeur:"nom_programme",
        type:"caractere",
        table:"programmes",
        libelle:"Programme",
      },
      {
        valeur:"numero_marche",
        type:"caractere",
        table:"marches",
        libelle:"Marché",
      },
      {
        valeur:"libelle",
        type:"caractere",
        table:"activites",
        libelle:"Activité",
      },
      {
        valeur:"objet",
        type:"caractere",
        table:"decomptes",
        libelle:"Objet des travaux",
      },
      {
        valeur:"type_entretien",
        type:"caractere",
        table:"decomptes",
        libelle:"Type d'entretien",
      },
      {
        valeur:"date_facture",
        type:"date",
        table:"decomptes",
        libelle:"Date de transmission",
      },
      {
        valeur:"date_approbation",
        type:"date",
        table:"decomptes",
        libelle:"Date d'approbation",
      },
      {
        valeur:"date_debut_execution",
        type:"date",
        table:"decomptes",
        libelle:"Date de début d'exécution",
      },
      {
        valeur:"date_demarrage_effectif",
        type:"date",
        table:"decomptes",
        libelle:"Date de démarrage effectif",
      },
      {
        valeur:"date_fin_execution",
        type:"date",
        table:"decomptes",
        libelle:"Date de fin d'exécution",
      },
      {
        valeur:"date_ordre_service",
        type:"date",
        table:"decomptes",
        libelle:"Date d'ordre de service",
      },
      {
        valeur:"date_ordonnancement",
        type:"date",
        table:"decomptes",
        libelle:"Date d'ordonnancement",
      },
      {
        valeur:"montant_ordonnancement",
        type:"numeric",
        table:"decomptes",
        libelle:"Montant d'ordonnancement",
      },
      {
        valeur:"montant_facture_decompte",
        type:"numeric",
        table:"decomptes",
        libelle:"Montant",
      },
    ],
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    baseUrl: process.env.VUE_APP_UPLOAD,
    url:"",
    pIndex: 1,
    long: "",
    vlong: "",
    dlong: "",
    vIndex: 1,
    dIndex: 1,
    selection:"fer",
    loaded: false,
    loading:false,
    vloaded: false,
    dloaded: false,
    pagenumber:0,
    size:15,
    type:"general",
    user: "",
    listExtensionFiles:"",
    pageNumberValidation:0,
    dernier_valideur:[],
    vdernier_valideur:[],
    nb_nouveau_decomptes:"",
  }),
  watch: {
    // decomptes() {
    //   if (this.decomptes.donnees) {
    //     this.loaded = true
    //     this.long = this.decomptes.donnees.length
    //     this.$refs["btn-close"].click()
    //     this.decomptes.donnees.forEach(decompte => {
    //       // get le dernier valideur pour la condition du champ "dernière décision"
    //       if (decompte.dernier_valideur != null) {
    //         this.dernier_valideur.push(decompte.dernier_valideur.split("_"))
    //       }else{
    //         this.dernier_valideur.push(decompte.dernier_valideur)
    //       }
    //       if (decompte.statut_final !== "VALIDE") {
    //         // console.log(decompte.statut_final)
    //         decompte.statut_final = decompte.statut_final.split("_")
    //         decompte.statut_final = decompte.statut_final[0] + " " + decompte.statut_final[1]
    //         if (decompte.action_dernier_valideur !== "VALIDE" && decompte.action_dernier_valideur !== "MODIFIER" && decompte.action_dernier_valideur !== null) {
    //           decompte.action_dernier_valideur = decompte.action_dernier_valideur.split("_")
    //           decompte.action_dernier_valideur = decompte.action_dernier_valideur[0] + " " + decompte.action_dernier_valideur[1]
    //         }
    //       }
    //     })
    //     // console.log(this.dernier_valideur)
    //   }
    // },
    // validDecomptes() {
    //   this.vloaded = true
    //   this.vlong = this.validDecomptes.donnees.length
    //   // console.log(this.validDecomptes)
    //   this.validDecomptes.donnees.forEach(decompte => {
    //     if (decompte.statut_final != "VALIDE") {
    //       decompte.statut_final = decompte.statut_final.split("_")
    //       decompte.statut_final = decompte.statut_final[0] + " " + decompte.statut_final[1]
    //       if (decompte.action_dernier_valideur !== "VALIDE" && decompte.action_dernier_valideur !== "MODIFIER" && decompte.action_dernier_valideur !== null) {
    //         decompte.action_dernier_valideur = decompte.action_dernier_valideur.split("_")
    //         decompte.action_dernier_valideur = decompte.action_dernier_valideur[0] + " " + decompte.action_dernier_valideur[1]
    //       }
    //     }
    //   })
    // },
    notifDecompte(){
      if (this.notifDecompte) {
        this.nb_nouveau_decomptes=this.notifDecompte.donnees
        console.log(this.nb_nouveau_decomptes)
      }
    },
    decaissDecomptes() {
      this.dloaded = true
      this.dlong = this.decaissDecomptes.donnees.length
      console.log(this.decaissDecomptes)
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
        this.loading=false
      }
    },
    decompterrors(){
      if (this.decompterrors) {
        this.notif.message = this.decompterrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
            this.setError("")
            this.setFiles("")
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
    }
  },
  mounted() {
    localStorage.setItem("module","decomptes")
    // this.Decomptes()
    // this.DecomptesValider(this.validateData)
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.listExtensionFiles=utils.formaFichierAccepte
    this.user = userdetails.user_role.split("_")
    this.getNotifDecompte()
    // if (this.user[0]=="DAF" || this.user[0]=="ADMIN") {
    //   this.DecomptesDecaisser()
    // }
    
  },
  computed: {
    ...mapGetters(["decomptes", "files", "errors", "validDecomptes","decompterrors","decaissDecomptes","failDecaissDecompte","notifDecompte","failNotifDecompte",]),
    // pageCount(){
    //   if(this.decomptes.donnees !== undefined){
    //     var l = this.decomptes.donnees.length
    //     var s = this.size
    //   }
    //   return Math.ceil(l/s)
    // },
    // listDonneeDecompte(){
    //   const start = this.pagenumber * this.size,
    //         end = start + this.size
    //   return this.decomptes.donnees.slice(start,end)
    // },

    // pageCountValidation(){
    //   return Math.ceil(this.vlong/this.size)
    // },
    // lesDecompteAValider(){
    //   const debut= this.pageNumberValidation * this.size,
    //         fin =debut + this.size
    //   return this.validDecomptes.donnees.slice(debut,fin)
    // }
  },
  methods: {
    ...mapActions(["Decomptes", "DecomptesValider", "pageDecomptes","saveFile", "importDecompte","DecomptesDecaisser","getNotifDecompte"]),
    ...mapMutations(["setDecomptes", "setValidDecomptes","setError","setFiles","setDecaissDecomptes","setFailDecaissDecomptes","setNotifDecompte","setFailNotifDecompte"]),
    launchValidation(id) {
      this.$router.push({ name: "DecDetail", params: { id: id } })
    },
    filtrer(type) {
      if (type == "general") {
        this.loaded = false
        this.long = null
        this.Decomptes(this.filtre)
      } else {
        this.vloaded = false
        this.vlong = null
        this.validateData.filtre=this.filtre
        this.DecomptesValider(this.validateData)
      }
    },
    restoring(type) {
      if (type == "general") {
        this.loaded = false
        this.long = null
        // this.setMarches("")
        this.Decomptes()
        this.decompteData = { libelle: null, order: null, tri: null }
      } else {
        this.vloaded = false
        this.vlong = null
        this.validateData = { libelle: null, order: "asc", tri: null, en_attente: 1 }
        this.DecomptesValider(this.validateData)
      }
    },
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      // this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]
      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
        this.loading= true
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    submit() {
      this.importDecompte({url:this.url})
    },
    // paging(url) {
    //   if (this.pIndex == 0) {
    //     this.pIndex = 1
    //   }
    //   if (this.vIndex == 0) {
    //     this.vIndex = 1
    //   }
    //   this.goodUrl = url.split("/")
    //   url = this.goodUrl[1]
    //   var count=1
    //   var used=0
    //   url+="&filtres=["  
    //   this.filtre.forEach(filtre => {
    //     if (filtre.type == "date") {
    //       if (filtre.operation!="" && filtre.valeur!="") {
    //         if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
    //           if (count>1 && used !=0) {
    //             url+=","
    //           }
    //           url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
    //             +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
    //             +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
    //             +filtre.type+'"}'
    //           used+=1
    //         }else{
    //           if (count>1 && used !=0) {
    //             url+=","
    //           }
    //           url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
    //             +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
    //             +filtre.type+'"}'
    //           used+=1
    //         }
    //       }
    //     }else{
    //       if (filtre.valeur!="" && filtre.nature!="") {
    //         if (count>1 && used !=0) {
    //           url+=","
    //           console.log(count)
    //         }
    //         url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
    //         used+=1
    //       }
    //     }
    //     count+=1
    //   })
    //   url+="]"
    //   this.loaded = false
    //   this.long = null
    //   this.vloaded = false
    //   this.vlong = null
    //   this.setDecomptes("")
    //   this.setValidDecomptes("")
    //   this.pageDecomptes(url)
    // },
    getProfileDernierValideur(valideur) {
      if (valideur !== null) {
        return utils.getProfileDernierValideur(valideur)
      } else {
        return "non validé"
      }
    },
    getTypeDecompte(type) {
      return (utils.getTypeDecompte(type)[1] + " " + utils.getTypeDecompte(type)[2]).toUpperCase()
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    viderFile(){
      this.inputFiles.donnees_histo=''
      this.setFiles('')
      this.$refs["donnees_histo"].value=""
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    selectionOnglet(key){
      this.selection=""
      this.selection=key
    }
    // nextPage(){
    //   if(this.pagenumber +1 < this.pageCount){
    //     this.pagenumber++
    //   }
    // },
    // previewPage(){
    //   this.pagenumber--
    // },

    // nextPageValidation(){
    //   if(this.pageNumberValidation +1 < this.pageCountValidation){
    //     this.pageNumberValidation++
    //   }
    // },
    // previewPageValidation(){
    //   this.pageNumberValidation--
    // }
  }
}
</script>
